import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { RootState } from '../../../store/store';
import {
  closeSpecialistDialogAddEdit, fetchSpecialists, setImage,
  setInputDescription,
  setInputEmail, setInputHidden,
  setInputName,
  SpecialistsPageState,
} from '../specialistsPage.slice';
import specialistsService from '../../../services/specialists/specialists.service';
import { toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';
import { dataURItoBlob } from '../../../utils/dataUriToBlob';
import UploadImage from '../../../components/UploadImage';
import React from 'react';

export const SpecialistDialogAddEdit = () => {
  const dispatch: ThunkDispatch<SpecialistsPageState, any, AnyAction> = useDispatch();

  const {
    inputs: { email, description, name, id, photo, hidden },
    mode,
    open: openDialogAddEdit,
  } = useSelector((state: RootState) => state.specialists.dialogs.addEdit);
  const { data: specialists } = useSelector((state: RootState) => state.specialists);
  const { studioId } = useSelector((state: RootState) => state.app);

  const dialogClose = () => dispatch(closeSpecialistDialogAddEdit({}));

  const removeImage = async () => { dispatch(setImage('')) };

  const handleSubmit = async (mode: 'add' | 'edit') => {
    let textAction, textActionPast;

    try {
      if (!studioId) {
        throw new Error('Studio not found');
      }

      let specialistId = id;
      if (mode === 'add') {
        [textAction, textActionPast] = ['invite', 'invited'];

        if (email === '') {
          throw new Error('E-mail cannot be empty.');
        }

        const newSpecialist = await specialistsService.add({
          email,
          studioId,
          name,
          description,
          hidden,
        });
        specialistId = newSpecialist.id;
      } else {
        [textAction, textActionPast] = ['edit', 'edited'];

        await specialistsService.edit(id as number, studioId, {
          name,
          description,
          hidden,
        });
      }

      if (photo?.includes('data:image')) {
        const file = dataURItoBlob(photo);
        await specialistsService.uploadImage(specialistId as number, file, studioId);
      } else if (!photo) {
        const currentSpecialist = specialists.find((specialist) => specialist.id === id);
        if (currentSpecialist && currentSpecialist.photo) {
          await specialistsService.deleteImage(id as number, studioId);
        }
      }

      dispatch(fetchSpecialists(studioId));
      dialogClose();

      dispatch(toggleDialogInfo({
        open: true,
        type: 'info',
        message: `Successfully ${textActionPast}.`,
      }));

      setTimeout(() => {
        dispatch(toggleDialogInfo({ open: false }));
      }, 3000);
    } catch (error: any) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: `Error while ${textAction} specialist: ${error.message}`,
      }));
    }
  };

  return (
    <Dialog open={openDialogAddEdit} onClose={dialogClose}>
      <DialogTitle>{mode === 'add' ? 'Invite specialist' : 'Edit specialist'}</DialogTitle>
      <DialogContent>
        {mode === 'add' && (
          <TextField
            label="E-mail"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => dispatch(setInputEmail(e.target.value))}
            sx={{ marginBottom: 1, marginTop: 1 }}
          />
        )}
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          value={name}
          onChange={(e) => dispatch(setInputName(e.target.value))}
          sx={{ marginBottom: 1, marginTop: 1 }}
        />
        <TextField
          multiline={true}
          rows={6}
          label="Description"
          variant="outlined"
          fullWidth
          onChange={(e) => dispatch(setInputDescription(e.target.value))}
          value={description}
          sx={{ marginTop: 1, marginBottom: 1 }}
        />
        <FormControlLabel control={<Checkbox
          checked={!hidden}
          onChange={(e) => dispatch(setInputHidden(!e.target.checked))}
        />} label="Visible specialist" />

        <UploadImage image={photo || ''} setImage={setImage} removeImage={removeImage} />
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogClose}>Cancel</Button>
        <Button onClick={() => handleSubmit(mode)} variant="contained" color="primary">
          {mode === 'edit' ? 'Save Changes' : 'Invite specialist'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
