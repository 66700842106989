import BaseService from '../base/base.service';
import { Room, RoomCreate, RoomEdit } from './rooms.types';
import { ApiService } from '../api/api.service';

class RoomsService extends BaseService<Room, RoomCreate, RoomEdit> {
  readonly url = 'rooms';

  // @ts-ignore
  public override async getList(studioId: number): Promise<Room[]> {
    // TODO: нафига нужен фильтр по неактивным комнатам?
    const response = await ApiService.get<Room[]>(this.url + '?studioId=' + studioId + '&enabled=true'); // try / catch
    return response.data;
  }

  public async uploadImage(id: number, file: Blob): Promise<void> {
    const formData = new FormData();
    formData.append('photo', file);

    const response = await ApiService.upload(`rooms/${id}/photo`, formData);
    return response.data;
  }

  public async deleteImage(id: number): Promise<void> {
    await ApiService.delete(`rooms/${id}/photo`);
  }
}

const roomService = new RoomsService();

export default roomService;
