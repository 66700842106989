import { ScheduleCreateDTO, ScheduleDTO, ScheduleEditDTO, ScheduleExtendedDTO } from './schedules.types';
import { ApiService } from '../api/api.service';
import { Schedule, Participant } from '../../pages/schedule/schedulePage.types';

class SchedulesService {
  public static async add(scheduleCreate: ScheduleCreateDTO): Promise<void> {
      await ApiService.post('schedule', scheduleCreate);
  }

  public static async delete(scheduleId: string): Promise<void> {
    await ApiService.delete(`schedule/${scheduleId}`);
  }

  public static async edit(scheduleEdit: ScheduleEditDTO, scheduleId: string): Promise<void> {
    await ApiService.put(`schedule/${scheduleId}`, scheduleEdit);
  }

  public static async getList(roomId: number, dateStart: string, dateEnd: string): Promise<Schedule[]> {
    const response = await ApiService.get<ScheduleDTO[]>(`schedule?roomId=${roomId}&dateStart=${dateStart}&dateEnd=${dateEnd}`);

    return response.data.map((record) => ({
      startDate: record.start,
      endDate: record.end,
      ...record,
    }));
  }

  public static async getParticipants(scheduleId: string): Promise<Participant[]> {
    const response = await ApiService.get<ScheduleExtendedDTO>(`schedule/${scheduleId}`);

    const participants = (response.data.participants || []).map((participant) => ({
      ...participant,
      id: participant.bookingId,
    }));

    return participants;
  }
}

export default SchedulesService;
