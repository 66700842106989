import { Box, Tab } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { RootState } from '../../store/store';
import { Service } from '../../services/services/services.types';
import { setTab } from './servicesPage.slice';
import ServicesGrid from './components/ServicesGrid';
import ServiceGroupsGrid from './components/ServiceGroupsGrid';

const ServicesPage = () => {
  const dispatch: ThunkDispatch<Service[], any, AnyAction> = useDispatch();

  const { tab } = useSelector((state: RootState) => state.services);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    dispatch(setTab(newValue));
  };

  return (
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="">
            <Tab label="Services" value="1" />
            <Tab label="Service groups" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ p: '10px 0px' }}>
          <ServicesGrid />
        </TabPanel>
        <TabPanel value="2" sx={{ p: '10px 0px' }}>
          <ServiceGroupsGrid />
        </TabPanel>
      </TabContext>
  );
};

export default ServicesPage;
