import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

type DialogDeleteProps = {
  isOpen: boolean,
  onClose: () => void,
  onSubmit: () => void,
};

export const DialogResendInvite = (props: DialogDeleteProps) => {
  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <DialogTitle>Resend invitation</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to resend invitation to specialist?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={props.onSubmit} color="primary">
          Confirm resend
        </Button>
      </DialogActions>
    </Dialog>
  );
}
