import { createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import servicesService from '../services/services/services.service';
import { Service, ServiceDTO } from '../services/services/services.types';
import specialistsService from '../services/specialists/specialists.service';
import { Specialist, SpecialistResource } from '../services/specialists/specialists.types';
import { RoomDict } from '../services/rooms/rooms.types';
import RoomsService from '../services/rooms/rooms.service';
import { Studio } from '../services/studios/studios.types';
import StudiosService from '../services/studios/studios.service';
import TimezonesService from '../services/timezones/timezones.service';
import CurrenciesService from '../services/currencies/currencies.service';
import { RootState } from './store';
import { InitialValues } from '../types/types';
import serviceGroupsService from '../services/serviceGroups/serviceGroups.service';
import { ServiceGroup, ServiceGroupDTO } from '../services/serviceGroups/serviceGroups.types';
import ClientGroupsService from '../services/clientGroups/clientGroups.service';
import { ClientGroup } from '../services/clientGroups/clientGroups.types';

const specialistColors = [
  '#F3BDBD',
  '#DCF4B4',
  '#BFDEFA',
  '#ECBDF3',
  '#96E79E',
  '#fff2cc',
  '#f4cccc',
  '#fce5cd',
  '#d9ead3',
  '#d0e0e3',
  '#cfe2f3',
  '#d9d2e9',
  '#ead1dc',
  '#dac3ab',
  '#ea9f54',
  '#f77bbd',
  '#a7a8cd',
  '#c5c6c8',
  '#c2e2db',
  '#95cda6',
  '#b9fa8f',
  '#d3cd9a',
  '#f3d5b8',
  '#f0d8d3',
  '#fff2cc',
  '#f4cccc',
  '#fce5cd',
  '#d9ead3',
  '#d0e0e3',
  '#cfe2f3',
  '#d9d2e9',
  '#ead1dc',
  '#cfcf17',
  '#ea9f54',
  '#f77bbd',
  '#a7a8cd',
  '#c5c6c8',
  '#c2e2db',
  '#95cda6',
  '#b9fa8f',
  '#d3cd9a',
  '#f3d5b8',
  '#f0d8d3',
];

export type DictionariesState = {
  rooms: RoomDict[];
  specialists: Record<number, SpecialistResource[]>;
  studios: Studio[];
  services: Service[];
  serviceGroups: ServiceGroup[];
  clientGroups: ClientGroup[];
  timezones: string[];
  currencies: string[];
};

const initialState: DictionariesState = {
  rooms: [],
  studios: [],
  specialists: {},
  services: [],
  serviceGroups: [],
  clientGroups: [],
  timezones: [],
  currencies: [],
};

export const fetchTimezones = createAsyncThunk<string[]>(
'dictionaries/fetchTimezones',
async (_, { rejectWithValue }) => {
  try {
    const response = await TimezonesService.getList();
    return response;
  } catch (error: any) {
    return rejectWithValue('Error while fetch timezones');
  }
});

export const fetchCurrencies = createAsyncThunk<string[]>(
    'dictionaries/fetchCurrencies',
    async (_, { rejectWithValue }) => {
      try {
        const response = await CurrenciesService.getList();
        return response;
      } catch (error: any) {
        return rejectWithValue('Error while fetch currencies');
      }
    });

export const fetchServicesDict = createAsyncThunk<ServiceDTO[]>(
'dictionaries/fetchServices',
async (_, { rejectWithValue }) => {
  try {
    const response = await servicesService.getList();
    return response;
  } catch (error: any) {
    return rejectWithValue('Error while fetch services');
  }
});

export const fetchServiceGroupsDict = createAsyncThunk<ServiceGroupDTO[]>(
'dictionaries/fetchServiceGroups',
async (_, { rejectWithValue }) => {
  try {
    const response = await serviceGroupsService.getList();
    return response;
  } catch (error: any) {
    return rejectWithValue('Error while fetch service groups');
  }
});

export const fetchSpecialists = createAsyncThunk<{ studioId: number; specialists: SpecialistResource[] }, number>(
'dictionaries/fetchSpecialists',
async (studioId, { rejectWithValue }) => {
  try {
    const response = await specialistsService.getList(studioId);
    return {
      studioId,
      specialists: response.map((specialist, index) => ({
        ...specialist,
        color: specialistColors[Math.min(index, 19)]
      }))
    };
  } catch (error: any) {
    return rejectWithValue('Error while fetch specialists');
  }
});

export const fetchStudiosDict = createAsyncThunk<Studio[]>(
'dictionaries/fetchStudios',
async (_, { rejectWithValue }) => {
  try {
    const response = await StudiosService.getList();
    return response;
  } catch (error: any) {
    return rejectWithValue('Error while fetch studios');
  }
});

export const fetchRoomsDict = createAsyncThunk<RoomDict[], number>(
'dictionaries/fetchRooms',
async (studioId: number, { rejectWithValue }) => {
  try {
    const response = await RoomsService.getList(studioId);
    return response;
  } catch (error: any) {
    return rejectWithValue('Error while fetch rooms');
  }
});

export const fetchClientGroupsDict = createAsyncThunk<ClientGroup[], number>(
'dictionaries/fetchClientGroups',
async (studioId: number, { rejectWithValue }) => {
  try {
    const response = await ClientGroupsService.getList(studioId);
    return response;
  } catch (error: any) {
    return rejectWithValue('Error while fetch client groups');
  }
});

export const prepareResources = createAsyncThunk<{ specialists: Specialist[], services: Service[]}, number>(
'dictionaries/fetchResources',
async (studioId: number, { rejectWithValue, getState, dispatch }) => {
  try {
    let { services, specialists } = (getState() as RootState).dictionaries;
    const { studioId } = (getState() as RootState).app;

    if (studioId !== InitialValues.UNKNOWN_ID) {
      await dispatch(fetchServicesDict());
      await dispatch(fetchSpecialists(studioId));
    }

    ({ services, specialists } = (getState() as RootState).dictionaries); // read once again

    return {
      specialists: specialists[studioId],
      services,
    };
  } catch (error: any) {
    return rejectWithValue('Error while fetch resources');
  }
});

const dictionariesSlice = createSlice<DictionariesState, SliceCaseReducers<DictionariesState>>({
  name: 'dictionaries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoomsDict.fulfilled, (state: DictionariesState, action) => {
        state.rooms = action.payload;
      })
      .addCase(fetchServicesDict.fulfilled, (state: DictionariesState, action) => {
        state.services = action.payload.map((service) => ({
          id: service.id,
          name: service.name,
          description: service.description,
          groupId: service.serviceGroups?.[0]?.id ?? null,
          deletedAt: service.deletedAt,
          hidden: service.hidden,
        }));
      })
      .addCase(fetchServiceGroupsDict.fulfilled, (state: DictionariesState, action) => {
        state.serviceGroups = action.payload;
      })
      .addCase(fetchSpecialists.fulfilled, (state: DictionariesState, action) => {
        state.specialists[action.payload.studioId] = action.payload.specialists;
      })
      .addCase(fetchStudiosDict.fulfilled, (state: DictionariesState, action) => {
        state.studios = action.payload;
      })
      .addCase(fetchTimezones.fulfilled, (state: DictionariesState, action) => {
        state.timezones = [...action.payload];
      })
      .addCase(fetchCurrencies.fulfilled, (state: DictionariesState, action) => {
        state.currencies = action.payload;
      })
      .addCase(fetchClientGroupsDict.fulfilled, (state: DictionariesState, action) => {
        state.clientGroups = action.payload;
      });
  },
});

const dictionariesReducer = dictionariesSlice.reducer;

export default dictionariesReducer;
