import React from 'react';
import { AppointmentForm, Appointments, WeekView } from '@devexpress/dx-react-scheduler-material-ui';
import { alpha, styled } from '@mui/material/styles';
import { AppointmentModel } from '@devexpress/dx-react-scheduler';
import { DateTime } from 'luxon'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { AppointmentComponentContent } from './AppointmentComponentContent';
import { classes } from './.classes';
import { LocalizationProvider, TimePicker, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { IconButton, TextField } from '@mui/material';
import { SpecialistResource } from '../../services/specialists/specialists.types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import PeopleIcon from '@mui/icons-material/People';
import { Service } from '../../services/services/services.types';

const StyledWeekViewTimeTableCell = styled(WeekView.TimeTableCell)(({ theme }) => ({
  [`&.${classes.todayCell}`]: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    // height: '100px',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.14),
    },
    '&:focus': {
      backgroundColor: alpha(theme.palette.primary.main, 0.16),
    },
  },
  [`&.${classes.weekendCell}`]: {
    backgroundColor: alpha(theme.palette.action.disabledBackground, 0.04),
    // height: '100px',
    '&:hover': {
      backgroundColor: alpha(theme.palette.action.disabledBackground, 0.04),
    },
    '&:focus': {
      backgroundColor: alpha(theme.palette.action.disabledBackground, 0.04),
    },
  },
}));

const StyledWeekViewDayScaleCell = styled(WeekView.DayScaleCell)(({ theme }) => ({
  [`&.${classes.today}`]: {
    backgroundColor: alpha(theme.palette.primary.main, 0.16),
  },
  [`&.${classes.weekend}`]: {
    backgroundColor: alpha(theme.palette.action.disabledBackground, 0.06),
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  [`&.${classes.button}`]: {
    padding: 0,
  },
}));

export const TimeTableCell = (props: any) => {
  const { startDate } = props;
  const date = new Date(startDate);

  if (date.getDate() === new Date().getDate()) {
    return <StyledWeekViewTimeTableCell {...props} className={classes.todayCell} />;
  } if (date.getDay() === 0 || date.getDay() === 6) {
    return <StyledWeekViewTimeTableCell {...props} className={classes.weekendCell} />;
  } return <StyledWeekViewTimeTableCell {...props} />;
};

export const DayScaleCell = (props: any) => {
  const { startDate, today } = props;

  if (today) {
    return <StyledWeekViewDayScaleCell {...props} className={classes.today} />;
  } if (startDate.getDay() === 0 || startDate.getDay() === 6) {
    return <StyledWeekViewDayScaleCell {...props} className={classes.weekend} />;
  } return <StyledWeekViewDayScaleCell {...props} />;
};

export const TextEditor = (props: any) => {
  if (props.placeholder === "Notes" || props.placeholder === 'Title') {
    return null;
  }
  return <AppointmentForm.TextEditor {...props} />;
};

export const TimeField = (props: any) => {
  return <LocalizationProvider dateAdapter={AdapterLuxon}>
    <TimePicker label={props.label}
                ampm={false}
                value={props.value}
                renderInput={(props) => <TextField {...props} />}
                onChange={(newValue: DateTime | null) => {
                  return newValue ? props.onValueChange(newValue.toJSDate()) : null;
                }}
    />
  </LocalizationProvider>;
};

export const DateTimeField = (props: any) => {
  return <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={"ru-RU"}>
    <DateTimePicker
      label={props.label}
      ampm={false}
      minDateTime={DateTime.now()}
      value={props.value}
      renderInput={(props) => <TextField {...props} />}
      onChange={(newValue: DateTime | null) => {
        return newValue ? props.onValueChange(newValue.toJSDate()) : null;
      }}
  />
  </LocalizationProvider>;
};

export const LabelComponent = (props: any) => {
  if (props.text === 'Details' || props.text === 'More Information') {
    return null;
  }
  return <AppointmentForm.Label {...props} />;
};

export const AppointmentComponent = ({ data, studioId, ...restProps }: AppointmentModel, allowDrag: (data: AppointmentModel) => boolean, showAppointment?: any) => {
  const { specialists, services } = useSelector((state: RootState) => state.dictionaries);
  const specialist: SpecialistResource | undefined = (specialists[studioId] || []).find((specialist: SpecialistResource) => specialist.id === data.specialistId);
  const service: Service | undefined = (services || []).find((service: Service) => service.id === data.serviceId);
  const isMatrix = !showAppointment;

  const notRegularStyle = (!isMatrix && !data.regular) ? `
      repeating-linear-gradient(
        -45deg, 
        rgba(255, 204, 0, 0.3), 
        rgba(255, 204, 0, 0.3) 10px, 
        rgba(255, 204, 0, 0) 10px, 
        rgba(255, 204, 0, 0) 20px
      ),` : '';

  const style: any = {
    borderRadius: '8px',
    background: notRegularStyle + `linear-gradient(${specialist?.color}, ${specialist?.color})`
  };

  if (!allowDrag(data)) {
    style.cursor = 'not-allowed' ;
  }

  return (
      <Appointments.Appointment {...restProps} style={style} resources={[]} data={data} draggable={false}>
        <div className={classes.text} style={{
          width: 'calc(100% - 10px)',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '3px 5px 2px 5px',
          background: '#84b921'
        }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledIconButton
                className={classes.button}
                onClick={({target}) => { if (!isMatrix) showAppointment(); }}
                size="small"
            >
              <PeopleIcon sx={{
                width: '18px',
                height: '18px',
              }} />
            </StyledIconButton>
            &nbsp;
            <span style={{
              color: 'black',
              cursor: isMatrix ? 'no' : 'pointer',
              textDecoration: isMatrix ? 'no' : 'underline',
            }}
              onClick={({target}) => { if (!isMatrix) showAppointment(); }}
            >{isMatrix
              ? `Limit: ${data.limit}`
              : `Limit: ${data.limit - (data.participantsCount || 0)} / ${data.limit}`
            }</span>
          </div>

          {(service?.hidden || specialist?.hidden) && (
              <VisibilityOffIcon sx={{
                marginLeft: 'auto', // Прижимает иконку вправо
                width: '18px',
                height: '18px',
                opacity: 0.54
              }} />
          )}
        </div>

        <AppointmentComponentContent data={data} isMatrix={isMatrix} studioId={studioId} />
      </Appointments.Appointment>
  );
}
