import { Studio, StudioFields } from './studios.types';
import { ApiService } from '../api/api.service';

class StudiosService {
  public static async add(room: StudioFields): Promise<Studio> {
    const response = await ApiService.post('studios', room);
    return response.data;
  }

  public static async getList(): Promise<Studio[]> {
    const response = await ApiService.get<Studio[]>('studios');
    return response.data;
  }

  public static async edit(id: number, data: StudioFields): Promise<void> {
    const response = await ApiService.put(`studios/${id}`, data);
    return response.data;
  }

  public static async uploadImage(id: number, file: Blob): Promise<void> {
    const formData = new FormData();
    formData.append('photo', file);

    const response = await ApiService.upload(`studios/${id}/photo`, formData);
    return response.data;
  }

  public static async deleteImage(id: number): Promise<void> {
    await ApiService.delete(`studios/${id}/photo`);
  }

  public static async delete(id: number): Promise<boolean> {
    const response = await ApiService.delete(`studios/${id}`);
    return response.data;
  }
}

export default StudiosService;
