import { Box, Button, IconButton } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import specialistsService from '../../services/specialists/specialists.service';
import React, { useEffect, useState } from 'react';
import { Specialist } from '../../services/specialists/specialists.types';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import {
  fetchSpecialists,
  closeSpecialistDialogDelete,
  openSpecialistDialogAddEdit,
  openSpecialistDialogDelete,
} from './specialistsPage.slice';
import { toggleDialogInfo } from '../../components/dialog/dialogInfo.slice';
import { SpecialistDialogAddEdit } from './components/SpecialistDialogAddEdit';
import { DialogDelete } from '../../components/DialogDelete';
import { InitialValues } from '../../types/types';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DialogResendInvite } from './components/DialogResendInvite';

const SpecialistsPage = () => {
  const dispatch: ThunkDispatch<Specialist[], any, AnyAction> = useDispatch();
  const [isOpenDialogResend, openDialogResend] = useState<boolean>(false);
  const [resendId, setResendId] = useState<number>();

  const {
    data: specialists,
    dialogs: { delete: { deletingId, open: dialogDeleteOpened } },
  } = useSelector((state: RootState) => state.specialists);

  const { studioId } = useSelector((state: RootState) => state.app);

  const handleResendInvite = async() => {
    try {
      await specialistsService.resendInvite(resendId as number, studioId);
      openDialogResend(false);

      dispatch(toggleDialogInfo({
        open: true,
        type: 'info',
        message: 'Invite successfully resent',
      }));
    } catch (e) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: `Error while resend invite`,
      }));
    }
  };

  const handleConfirmDelete = async () => {
    if (deletingId !== null) {
      try {
        await specialistsService.delete(deletingId, studioId);
        dispatch(fetchSpecialists(studioId));
        dispatch(closeSpecialistDialogDelete({}));
        dispatch(toggleDialogInfo({
          open: true,
          type: 'info',
          message: 'Specialist successfully deleted',
        }));
        setTimeout(() => dispatch(toggleDialogInfo({ open: false })), 3000);
      } catch (error) {
        dispatch(toggleDialogInfo({
          open: true,
          type: 'error',
          message: 'Error while delete specialist: ' + error,
        }));
      }
    } else {
      console.error('Deleting id is not found');
    }
  };

  const columns: GridColDef[] = [{
    field: 'id', // hidden
  },{
    field: 'photo',
    headerName: 'Photo',
    width: 80,
    renderCell: (params) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={ params.row.photo || '/logo96.png' } width={40} alt={"params.row.name"} />
      </div>
    ),
  },{
    field: 'email',
    headerName: 'E-mail',
    width: 200
  },{
    field: 'name',
    headerName: 'Name',
    width: 200
  },{
    field: 'description',
    headerName: 'Description',
    sortable: false,
    flex: 1,
  },{
    field: 'status',
    headerName: 'Status',
    width: 150
  },{
    field: 'resend',
    headerName: 'Resend',
    width: 80,
    sortable: false,
    renderCell: (params) => (
        <>
        {params.row.status === 'pending' && <IconButton onClick={() => {
            setResendId(params.row.id);
            openDialogResend(true);
          }} >
              <ForwardToInboxIcon />
          </IconButton>}
        </>
    ),
  },{
    field: 'hidden',
    headerName: 'Visible',
    align: 'center',
    width: 65,
    renderCell: (params) => (
      <>
        {!params.row.hidden && (<VisibilityIcon sx={{ opacity: 0.54 }} />)}
        {params.row.hidden && (<VisibilityOffIcon sx={{ opacity: 0.54 }}  />)}
      </>
    ),
  },{
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    sortable: false,
    renderCell: (params) => (
      <>
        <IconButton onClick={ () => dispatch(openSpecialistDialogAddEdit({
          mode: 'edit',
          ...params.row,
        })) }>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => dispatch(openSpecialistDialogDelete(params.row.id))}>
          <DeleteIcon />
        </IconButton>
      </>
    ),
  }];

  useEffect(() => {
    if (studioId !== InitialValues.UNKNOWN_ID) {
      dispatch(fetchSpecialists(studioId));
    } else {
      // todo: clear
    }

  }, [dispatch, studioId]);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button
          variant="contained"
          sx={{ marginBottom: 1 }}
          onClick={ () => dispatch(openSpecialistDialogAddEdit({ mode: 'add' })) }
          startIcon={<AddCircleIcon />}
        >Invite specialist</Button>
      </Box>

      <DialogDelete
        open={dialogDeleteOpened}
        question="Are you sure you want to delete this specialist?"
        onClose={() => dispatch(closeSpecialistDialogDelete({}))}
        onSubmit={handleConfirmDelete}
      />

      <DialogResendInvite
        isOpen={isOpenDialogResend}
        onClose={() => { openDialogResend(false); } }
        onSubmit={handleResendInvite}
     />

      <SpecialistDialogAddEdit />

      <DataGrid
        sx={{ '&, [class^=MuiDataGrid-root]': { border: 'none' } }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            }
          }
        }}
        columns={columns}
        rows={specialists}
        hideFooter={true}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default SpecialistsPage;
