export function dataURItoBlob(dataURI: string) {
  try {
    const [meta, image] = dataURI.split(',');
    const type = meta.replace('data:','').replace(';base64', '');
    const binary = atob(image);
    const array = [];
    for(let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type });
  } catch (err: any) {
    console.error(err);
    throw new Error('Cannot process image.');
  }
}
