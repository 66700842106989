import { createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { Service, ServiceDTO } from '../../services/services/services.types';
import servicesService from '../../services/services/services.service';
import serviceGroupsService from '../../services/serviceGroups/serviceGroups.service';
import { ServiceGroup } from '../../services/serviceGroups/serviceGroups.types';

type DialogAddEditAction = {
  mode: 'add',
} | {
  mode: 'edit',
  id: number,
  name: string,
  description: string,
};

export type ServicesPageState = {
  tab: string,
  services: {
    data: Service[],
    dialogs: {
      addEdit: {
        open: boolean;
        mode: 'add' | 'edit',
        success: boolean;
        inputs: {
          id: number;
          groupId: number;
          name: string;
          description: string;
          hidden: boolean;
        }
      },
      delete: {
        open: boolean;
        deletingId: number;
      },
    },
  },
  serviceGroups: {
    data: ServiceGroup[],
    dataWithDeleted: ServiceGroup[],
    dialogs: {
      addEdit: {
        open: boolean;
        mode: 'add' | 'edit',
        success: boolean;
        inputs: {
          id: number;
          name: string;
          description: string;
        }
      },
      delete: {
        open: boolean;
        deletingId: number;
      },
    },
  }
};

const initialState: ServicesPageState = {
  tab: '1',
  services: {
    data: [],
    dialogs: {
      addEdit: {
        open: false,
        mode: 'add',
        success: false,
        inputs: {
          id: -1,
          groupId: -1,
          name: '',
          description: '',
          hidden: false,
        }
      },
      delete: {
        open: false,
        deletingId: -1,
      },
    },
  },
  serviceGroups: {
    data: [],
    dataWithDeleted: [],
    dialogs: {
      addEdit: {
        open: false,
        mode: 'add',
        success: false,
        inputs: {
          id: -1,
          name: '',
          description: '',
        }
      },
      delete: {
        open: false,
        deletingId: -1,
      },
    },
  },
};

export const fetchServices = createAsyncThunk<ServiceDTO[]>(
'services/fetchServices',
async (_, { rejectWithValue }) => {
  try {
    const response = await servicesService.getList();

    return response;
  } catch (error: any) {
    return rejectWithValue('Error while fetch services');
  }
});

export const fetchServiceGroups = createAsyncThunk<ServiceGroup[]>(
    'services/fetchServiceGroups',
    async (_, { rejectWithValue }) => {
      try {
        const response = await serviceGroupsService.getList();

        return response;
      } catch (error: any) {
        return rejectWithValue('Error while fetch service groups');
      }
    });

const servicesPageSlice = createSlice<ServicesPageState, SliceCaseReducers<ServicesPageState>>({
  name: 'services',
  initialState,
  reducers: {
    closeServiceDialogAddEdit: (state) => {
      state.services.dialogs.addEdit.open = false;
    },
    closeServiceGroupDialogAddEdit: (state) => {
      state.serviceGroups.dialogs.addEdit.open = false;
    },
    openServiceDialogAddEdit: (state, action: PayloadAction<DialogAddEditAction>) => {
      state.services.dialogs.addEdit.open = true;
      state.services.dialogs.addEdit.mode = action.payload.mode;

      if (action.payload.mode === 'add') {
        Object.assign(state.services.dialogs.addEdit.inputs, initialState.services.dialogs.addEdit.inputs);
      } else {
        Object.assign(state.services.dialogs.addEdit.inputs, action.payload);
      }
    },
    openServiceGroupDialogAddEdit: (state, action: PayloadAction<DialogAddEditAction>) => {
      state.serviceGroups.dialogs.addEdit.open = true;
      state.serviceGroups.dialogs.addEdit.mode = action.payload.mode;

      if (action.payload.mode === 'add') {
        Object.assign(state.serviceGroups.dialogs.addEdit.inputs, initialState.serviceGroups.dialogs.addEdit.inputs);
      } else {
        Object.assign(state.serviceGroups.dialogs.addEdit.inputs, action.payload);
      }
    },
    closeServiceDialogDelete: (state) => {
      state.services.dialogs.delete.open = false;
    },
    closeServiceGroupDialogDelete: (state) => {
      state.serviceGroups.dialogs.delete.open = false;
    },
    openServiceDialogDelete: (state, action: PayloadAction<number>) => {
      state.services.dialogs.delete.deletingId = action.payload;
      state.services.dialogs.delete.open = true;
    },
    openServiceGroupDialogDelete: (state, action: PayloadAction<number>) => {
      state.serviceGroups.dialogs.delete.deletingId = action.payload;
      state.serviceGroups.dialogs.delete.open = true;
    },
    setServiceInputId: (state, action: PayloadAction<number>) => {
      state.services.dialogs.addEdit.inputs.id = action.payload;
    },
    setServiceHidden: (state, action: PayloadAction<boolean>) => {
      state.services.dialogs.addEdit.inputs.hidden = action.payload;
    },
    setServiceInputName: (state, action: PayloadAction<string>) => {
      state.services.dialogs.addEdit.inputs.name = action.payload;
    },
    setServiceInputDescription: (state, action: PayloadAction<string>) => {
      state.services.dialogs.addEdit.inputs.description = action.payload;
    },
    setServiceInputGroupId: (state, action: PayloadAction<number>) => {
      state.services.dialogs.addEdit.inputs.groupId = action.payload;
    },
    setServiceGroupInputName: (state, action: PayloadAction<string>) => {
      state.serviceGroups.dialogs.addEdit.inputs.name = action.payload;
    },
    setServiceGroupInputDescription: (state, action: PayloadAction<string>) => {
      state.serviceGroups.dialogs.addEdit.inputs.description = action.payload;
    },
    setTab: (state, action: PayloadAction<string>) => {
      state.tab = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServices.fulfilled, (state: ServicesPageState, action) => {
        state.services.data = action.payload.map((service) => ({
          id: service.id,
          name: service.name,
          description: service.description,
          groupId: service.serviceGroups?.[0]?.id ?? null,
          deletedAt: service.deletedAt,
          hidden: service.hidden,
        }));
      })
      .addCase(fetchServiceGroups.fulfilled, (state: ServicesPageState, action) => {
        state.serviceGroups.data = action.payload.filter((serviceGroup) => !serviceGroup.deletedAt);
        state.serviceGroups.dataWithDeleted = action.payload;
      });
  },
});

const servicesReducer = servicesPageSlice.reducer;

export const {
  setTab,
  setServiceInputName,
  setServiceInputDescription,
  setServiceInputGroupId,
  openServiceDialogAddEdit,
  openServiceGroupDialogAddEdit,
  closeServiceDialogAddEdit,
  closeServiceGroupDialogAddEdit,
  openServiceDialogDelete,
  openServiceGroupDialogDelete,
  closeServiceDialogDelete,
  closeServiceGroupDialogDelete,
  setServiceGroupInputName,
  setServiceGroupInputDescription,
  setServiceHidden,
} = servicesPageSlice.actions;

export default servicesReducer;
