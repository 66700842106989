import { Specialist, SpecialistEdit, SpecialistInvite } from './specialists.types';
import BaseService from '../base/base.service';
import { ApiService } from '../api/api.service';
import { IGetListResponse } from '../base/base.types';

class SpecialistsService extends BaseService<Specialist, SpecialistInvite, SpecialistEdit> {
  readonly url = 'specialists';

  public async resendInvite(id: number, studioId: number): Promise<void> {
    await ApiService.patch(`${this.url}/resend-invite/${id}?studioId=${studioId}`, {});
  }

  // @ts-ignore
  public override async getList(studioId: number): Promise<Specialist[]> {
    const response = await ApiService.get<IGetListResponse<Specialist>>(this.url + '?studioId=' + studioId); // try / catch
    return response.data.elements;
  }

  // @ts-ignore
  public override async edit(id: number, studioId: number, data: TEdit): Promise<void> {
    const response = await ApiService.put(`${this.url}/${id}?studioId=${studioId}`, data);
    return response.data;
  }

  // @ts-ignore
  public override async delete(id: number, studioId: number): Promise<boolean> {
    const response = await ApiService.delete(`${this.url}/${id}?studioId=${studioId}`);
    return response.data;
  }

  public async uploadImage(id: number, file: Blob, studioId: number): Promise<void> {
    const formData = new FormData();
    formData.append('photo', file);

    const response = await ApiService.upload(`${this.url}/${id}/photo?studioId=${studioId}`, formData);
    return response.data;
  }

  public async deleteImage(id: number, studioId: number): Promise<void> {
    await ApiService.delete(`${this.url}/${id}/photo?studioId=${studioId}`);
  }
}

const specialistsService = new SpecialistsService();

export default specialistsService;
